import React, { useState, useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";

export const WeatherContext = React.createContext();

export const WeatherProvider = ({ children }) => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [weatherData, setWeatherData] = useState({});
  const [error, setError] = useState(undefined);


  const city = location.pathname.replace("/", "");

  const getWeather = useCallback(async () => {
    if (!city) return;
    const locations = {
      ottawa: {
        lat: 45.4215,
        long: -75.6972
      },
      moscow: {
        lat: 55.7558,
        long: 37.6173
      },
      tokyo: {
        lat: 35.6762,
        long: 139.6503
      },
    }
    try {
      const app_id = "b2c6503aa76bb56bbaede49137056d56";
      const response = await fetch(`https://api.openweathermap.org/data/2.5/onecall?lat=${locations[city].lat}&lon=${locations[city].long}&exclude=minutely,hourly,alerts&units=metric&appid=${app_id}`);
      const cityData = await response.json()
      return cityData;
    } catch (e) {
      return Promise.reject(e);
    }
  }, [city])

  useEffect(() => {
    if (!city) return;
    let cancelled = false;
    let currentTime = new Date().getTime();
    let cachedData = weatherData[city];
    if (cachedData && currentTime - cachedData.time < 20000) {
      return;
    }
    setLoading(true);
    setError(undefined);
    getWeather()
      .then((cityData) => {
        if (cancelled) return;
        setWeatherData({ 
          ...weatherData,
          [city]: {
            ...cityData,
            time: currentTime,
          }});
      })
      .catch((e) => {
        console.log(e);
        setError(e);
      })
      .finally(() => setLoading(false));

    return () => cancelled = true;
  }, [city, weatherData, getWeather]);

  return (
    <WeatherContext.Provider value={{ cityData: weatherData[city], loading, error}}>
      { children }
    </WeatherContext.Provider>
  )
}