import React, { useContext } from "react";
import { NavWrapper, Link } from "./NavStyles";
import { WeatherContext } from "../../context/WeatherContext";

export const Nav = () => {
  const { loading } = useContext(WeatherContext);

  return (
    <NavWrapper>
      <Link disabled={loading} to="/ottawa">OTTAWA</Link>      
      <Link disabled={loading} to="/moscow">MOSCOW</Link>     
      <Link disabled={loading} to="/tokyo">TOKYO</Link>
    </NavWrapper>
  )
}

export default Nav;