import React from 'react';
import { ThemeProvider } from 'styled-components';
import { AppWrapper } from "./AppStyles";
import { Nav } from "../Nav/Nav";
import { WeatherReadings } from "../WeatherReadings/WeatherReadings";
import { WeatherProvider } from "../../context/WeatherContext";
import { 
  BrowserRouter as Router, 
  Switch, 
  Route,
  Redirect
} from "react-router-dom";

function App() {

  const theme = {
    bgColor: "#eef6fb",
    fontColor: "#333339",
    activeFontColor: "#5fb0e8",
    border: "3px solid white",
  };

  return (
    <Router>
      <WeatherProvider>
        <ThemeProvider theme={theme}>
          <AppWrapper>
            <Nav/>
            <Switch>
              <Route exact path="/:city" render={() => <WeatherReadings />} />
              <Redirect to="/ottawa"/>
            </Switch>
          </AppWrapper>
        </ThemeProvider>
      </WeatherProvider>
    </Router>

  );
}

export default App;
