export const getDateForTimezoneOffset = (timezoneOffset) => {
  let millisecondOffset = timezoneOffset * 1000;
  let d = new Date();
  let utc = new Date(
    d.getUTCFullYear(), 
    d.getUTCMonth(), 
    d.getUTCDate(), 
    d.getUTCHours(), 
    d.getUTCMinutes(), 
    d.getUTCSeconds(), 
    d.getUTCMilliseconds()
  );
  let offsetDate = new Date(utc.getTime() + millisecondOffset);
  return offsetDate;
}