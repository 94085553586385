import _338 from "../images/338.svg"
import _305 from "../images/305.svg"
import _200 from "../images/200.svg"
import _143 from "../images/143.svg"
import _119 from "../images/119.svg"
import _116 from "../images/116.svg"
import _116n from "../images/116n.svg"
import _113 from "../images/113.svg"
import _113n from "../images/113n.svg"

export default {
  _01d: _113,
  _01n: _113n,
  _02d: _116,
  _02n: _116n,
  _03d: _119,
  _03n: _119,
  _04d: _119,
  _04n: _119,
  _09d: _305,
  _09n: _305,
  _10d: _305,
  _10n: _305,
  _11d: _200,
  _11n: _200,
  _13d: _338,
  _13n: _338,
  _50d: _143,
  _50n: _143,
}
