import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const NavWrapper = styled.nav`
  display: flex;
  justify-content: center;
`;

export const Link = styled(NavLink)`
  width: 150px;
  text-align: center;
  padding: 20px 0px;
  box-sizing: border-box;
  text-decoration: none;
  font-size: 18px;
  transform: translateY(0px);
  transition: transform .25s;

  &:visited {
    color: ${({theme}) => theme.fontColor};
  }
  &:active {
    color: ${({theme}) => theme.fontColor};
  }
  &:hover {
    color: ${({theme}) => theme.fontColor};
    transform: translateY(-1px);
    transition: transform .25s;
  }
  &.active {
    font-weight: bold;
    color: ${({theme}) => theme.activeFontColor};
  }
`;