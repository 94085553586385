import React, { useContext } from "react";
import { WeatherContext } from "../../context/WeatherContext";
import { 
  WeatherBox,
  TodayWrapper,
  ForecastBox,
  ForecastDayWrapper,
  Temperature,
  Error
} from "./WeatherStyles";
import {
  FlexDiv,
} from "../../styles/common";
import iconMap from "../../helpers/iconMap";
import { getDateForTimezoneOffset } from "../../helpers/time";

export const WeatherReadings = () => {

  const { cityData, loading, error } = useContext(WeatherContext);
  const dailies = cityData?.daily;
  const timezoneOffset = cityData?.timezone_offset

  return (
    <>
      { error &&
        <Error>{error}</Error>
      }
      <WeatherBox>
        <TodayWeather loading={loading} data={cityData?.current}/>
        <ForecastBox>
          { 
            (new Array(4).fill(0)).map((n, i) => {
              return (
                <ForecastDay key={i} 
                             data={dailies?.[i]} 
                             daysAfterToday={i + 1} 
                             timezoneOffset={timezoneOffset}
                             loading={loading}/>
              )
            })
          }
        </ForecastBox>
      </WeatherBox>
    </>
  )
}

const TodayWeather = ({ data, loading }) => {
  if (loading || !data) {
    return (
      <TodayWrapper>
        <p>Loading...</p>
      </TodayWrapper>
    )
  }
  let temp = Math.round(data.temp);
  let weather = data.weather[0];
  let icon = iconMap["_" + weather.icon];
  let description = weather.main;

  return (
    <TodayWrapper>
      <p style={{marginBottom: 20}}>Today</p>     
      <FlexDiv direction="row">
        <img width="90" height="90" alt={description} src={icon}/>
        <FlexDiv align="flex-start">
          <Temperature size="50">{temp}°</Temperature>
          <p>{description}</p> 
        </FlexDiv>
      </FlexDiv>
    </TodayWrapper>
  )
}

const ForecastDay = ({ data, timezoneOffset, daysAfterToday, loading }) => {
  let date = getDateForTimezoneOffset(timezoneOffset);
  let days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  let day = days[(date.getDay() + daysAfterToday) % 7]

  if (!data || loading) {
    return (
      <ForecastDayWrapper>
      </ForecastDayWrapper>
    )
  }

  let temp = Math.round(data.temp.day);
  let weather = data.weather[0];
  let icon = iconMap["_" + weather.icon];
  let description = weather.main;

  return (
    <ForecastDayWrapper>
      <p>{day}</p>
      <img width="30" height="30" alt={description} src={icon}/>
      <Temperature size="20">{temp}°</Temperature>
    </ForecastDayWrapper>
  )
}