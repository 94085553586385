import styled from "styled-components";
import { FlexDiv } from "../../styles/common";

export const Error = styled.p`
  margin: 0;
  margin-bottom: 10px;
  padding: 0px;
  text-align: center;
  color: red;
  width: 100%;
`;

export const WeatherBox = styled.div`
  border: ${({theme}) => theme.border};
  box-shadow: 0px 5px 20px rgba(0,0,0, .1);
`;

export const Temperature = styled.div`
  font-family: Homenaje, Montserrat;
  font-weight: bold;
  font-size: ${({size}) => size}px;
  line-height: 1;
`

export const TodayWrapper = styled(FlexDiv)`
  border-bottom: ${({theme}) => theme.border};
  height: 185px;
  img {
    margin-right: 20px;
  }
  p {
    font-size: 18px;
    margin: 5px 0px;
  }
`;

export const ForecastBox = styled(FlexDiv)`
  flex-direction: row;
`;

export const ForecastDayWrapper = styled(FlexDiv)`
  flex-grow: 1;
  height: 130px;
  width: 110px;
  border-right: ${({theme}) => theme.border};
  &:last-child {
    border-right: none;
  }
  img {
    margin: 10px;
  }
  p {
    font-size: 14px;
    margin: 0;
  }
`;